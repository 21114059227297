import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import L from 'leaflet'
import React, { memo, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

export interface Props {
  cta?: ButtonProps
  description?: string
  imageSmall?: ImageProps
  label?: string
  longitude?: string
  latitude?: string
  title?: string
}

export const IntroMap = memo(function IntroMap({
  cta,
  description,
  imageSmall,
  label,
  longitude,
  latitude,
  title,
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  const bounds = [] as any

  bounds.push([latitude, longitude])

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [108, 145],
        iconAnchor: [54, 105],
        html: `<img src="/map_marker.png" alt="Agriturismo Cavazzone" width="108" height="145">`,
        className: 'main-icon',
      }),
    )
  }, [])

  return (
    <Container>
      <Wrapper>
        <Left>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {typeof window !== undefined && latitude && longitude ? (
            <Map
              center={[Number(latitude), Number(longitude)]}
              dragging={L.Browser && L.Browser.mobile ? false : true}
              zoom={13}
              scrollWheelZoom={false}
              whenCreated={(mapInstance: any) => {
                map.current = mapInstance
                mapInstance.fitBounds(bounds)
                mapInstance.setZoom(16)
              }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              />
              <Marker
                icon={markerIcon}
                position={[Number(latitude), Number(longitude)]}
              />
            </Map>
          ) : null}
        </Left>
        <Right>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {cta ? (
            <FadeInUp>
              <StyledButton {...cta} />
            </FadeInUp>
          ) : null}
          {imageSmall ? <ImageSmall {...imageSmall} /> : null}
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Map = styled(MapContainer)`
  position: relative;
  display: block;
  max-width: 49.0625rem;
  margin-top: 9.0625rem;
  margin-left: -8.9375rem;
  aspect-ratio: 1;

  @media (max-width: 1440px) {
    margin-top: 6.25rem;
  }

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }

  @media (max-width: 992px) {
    width: calc(100% + 2.5rem);
    margin-top: 2.5rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .leaflet-pane {
    z-index: 1 !important;
  }

  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: 1 !important;

    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;

      a {
        position: relative;
        width: 3.4375rem;
        height: 3.4375rem;
        background: ${({ theme }) => theme.colors.variants.primaryLight};
        border: 0;
        border-radius: 0% !important;
        line-height: 2.875rem;
        text-indent: -9999px;
        transition: 0.2s ease-out;

        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.primaryDark};

            &:before,
            &:after {
              background: ${({ theme }) => theme.colors.variants.primaryLight};
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.primaryDark};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-control-zoom-in {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.primaryDark};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            opacity: 0.2;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-bottom {
    display: none;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-top: 9.5rem;
  margin-left: 8.9375rem;

  @media (max-width: 1600px) {
    margin-left: 8.938vw;
  }

  @media (max-width: 1199px) {
    margin-left: 6.938vw;
  }

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    display: block;
  }
`

const ImageSmall = styled(Image)`
  position: relative;
  display: block;
  max-width: 33.125rem;
  margin-top: 7.1875rem;
  aspect-ratio: 530/600;

  @media (max-width: 1440px) {
    margin-top: 6.25rem;
  }

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-top: 2.5rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Label = styled.div`
  ${styles.label}
`

const Title = styled.h2`
  ${styles.h2}
  max-width: 37.5rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const Description = styled.div`
  ${styles.description}
  max-width: 26.75rem;
  margin-right: 6.25rem;

  @media (max-width: 1600px) {
    margin-right: 6.25vw;
  }

  @media (max-width: 1440px) {
    margin-right: 0;
  }

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const StyledButton = styled(Button)`
  margin-top: 3.4375rem;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`
